import Bugsnag from "@bugsnag/js";
import NextErrorComponent from "next/error";
import { useContext } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import UserContext from "../components/UserContext";

export default function CustomErrorPage({
    statusCode,
    hasGetInitialPropsRun,
    err,
}) {
    if (!hasGetInitialPropsRun && err) {
        // getInitialProps is not called in case of
        // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
        // err via _app.js so it can be captured
        Bugsnag.notify(err, (event) => {
            event.severity = "error";
            event.unhandled = true;
        });
    }
    const { user, supabase } = useContext(UserContext);

    return (
        <div>
            <div className="relative bg-gradient-to-b from-gray-200 to-gray-50 md:pb-2 border-b border-gray-200 dark:border-gray-800 dark:from-primary-superdark dark:to-gray-900">
                <div className="container mx-auto">
                    <Header
                        className="secondary"
                        supabase={supabase}
                        user={user}
                        title="Categories"
                    />
                </div>
            </div>

            <div className="  py-8 md:pt-10 my-16">
                <div className="max-w-4xl m-auto [&>*>h3]:font-bold">
                    <div className="flex justify-center">
                        <img
                            src="/error.svg"
                            alt="Oops!"
                            width="400"
                        />
                    </div>
                    <h1 className="text-center nice-title text-5xl mb-4">
                        Oops!
                    </h1>
                    <p className="text-center text-xl">Something went wrong.</p>
                </div>
            </div>
            <Footer user={user} />
        </div>
    );
}

CustomErrorPage.getInitialProps = async ({ req, res, err, asPath }) => {
    const errorInitialProps = await NextErrorComponent.getInitialProps({
        res,
        err,
    });

    // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
    // getInitialProps has run
    errorInitialProps.hasGetInitialPropsRun = true;

    // Running on the server, the response object (`res`) is available.
    //
    // Next.js will pass an err on the server if a page's data fetching methods
    // threw or returned a Promise that rejected
    //
    // Running on the client (browser), Next.js will provide an err if:
    //
    //  - a page's `getInitialProps` threw or returned a Promise that rejected
    //  - an exception was thrown somewhere in the React lifecycle (render,
    //    componentDidMount, etc) that was caught by Next.js's React Error
    //    Boundary. Read more about what types of exceptions are caught by Error
    //    Boundaries: https://reactjs.org/docs/error-boundaries.html

    if (err) {
        Bugsnag.notify(err, (event) => {
            event.severity = "error";
            event.unhandled = true;
            event.request = req;
        });

        // Flushing before returning is necessary if deploying to Vercel, see
        // https://vercel.com/docs/platform/limits#streaming-responses
        await require("@bugsnag/in-flight").flush(2000);

        return errorInitialProps;
    }

    if (
        asPath &&
        (asPath.endsWith(".php") || asPath.startsWith("."))
    ) {
        return errorInitialProps;
    }

    // If this point is reached, getInitialProps was called without any
    // information about what the error might be. This is unexpected and may
    // indicate a bug introduced in Next.js, so record it in Bugsnag
    Bugsnag.notify(
        new Error(`_error.js getInitialProps missing data at path: ${asPath}`),
        (event) => {
            event.severity = "error";
            event.unhandled = true;
            event.request = req;
        }
    );
    await require("@bugsnag/in-flight").flush(2000);

    return errorInitialProps;
};
